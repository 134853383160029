/* global VXConfig */

import React     from 'react';
import PropTypes from "prop-types";
import Flux      from '../../../flux/Flux';

const ALLOWED_AREA_CODES = ['+49', '+41', '+43', '+1', '+31', '+48'];

const VERIFICATION_TYPE = Flux.Constants.Pincall.VerificationTypes.UNIQUE_NUMBER;

// errors
const ERROR_TIMEOUT   = 4;
const ERROR_UNIQUE    = 6;
const ERROR_PIN_WRONG = 8;

/**
 * @param {String} countryCode
 * @returns {String}
 */
function getAreaClassName(countryCode) {
	return countryCode.replace('+', '-area-');
}

function withPincall(T) {
	class withPincall extends React.Component {

		constructor(props) {
			super(props);

			this.state = {
				countryCode:        ALLOWED_AREA_CODES[0],
				prevVerificationId: null,
				verificationId:     null,
				verificationCode:   '',
				isVerified:         false,
				errorCode:          null,
				allowedAreaCodes:   ALLOWED_AREA_CODES,
                pinLength:          Flux.Constants.Pincall.PinCallLength.MOBILE,
				getAreaClassName,
			};

			this.onSendVerificationCode       = this.onSendVerificationCode.bind(this);
			this.onVerifyCode                 = this.onVerifyCode.bind(this);
			this.onPincallVerificationStarted = this.onPincallVerificationStarted.bind(this);
			this.onPincallResultReceived      = this.onPincallResultReceived.bind(this);
		}

		componentDidMount() {
			Flux.Guest.addPincallVerificationStartedListener(this.onPincallVerificationStarted);
			Flux.Guest.addPincallResultReceivedListener(this.onPincallResultReceived);
		}

		componentWillUnmount() {
			Flux.Guest.removePincallResultReceivedListener(this.onPincallVerificationStarted);
			Flux.Guest.removePincallResultReceivedListener(this.onPincallResultReceived);
		}

		onPincallVerificationStarted(verificationId, errorCode, pinLength) {
			const newState = {};
			if (errorCode) {
				newState.errorCode = errorCode;
			} else if (verificationId) {
				newState.verificationId     = verificationId;
                newState.pinLength          = pinLength;
				newState.errorCode          = null;
				newState.prevVerificationId = null;
			}

			this.setState(newState, () => {
				if (this.state.errorCode === ERROR_UNIQUE && this.props.onFailed) {
						this.props.onFailed();
					}

				if (this.state.verificationId && this.pinRef) {
					this.pinRef.focus();
				}
			});
		}

		onPincallResultReceived(verificationId, errorCode) {
			const newState = {};
			if (errorCode) {
				newState.errorCode = errorCode;
			} else if (verificationId) {
				const verification = Flux.Guest.getPincallVerificationById(verificationId);

				newState.isVerified = verification && verification.verified;
				newState.errorCode  = null;
			}

			this.setState(newState, () => {
				if (this.state.isVerified && this.props.onVerified) {
						this.props.onVerified();
					}
			});
		}

		onSendVerificationCode(phoneNumber) {
			if (!phoneNumber) {
				return false;
			}

			const language = VXConfig.language === Flux.Constants.Languages.DE ? Flux.Constants.Pincall.Languages.DE : Flux.Constants.Pincall.Languages.EN;

			Flux.Guest.startPincallVerification(phoneNumber, VERIFICATION_TYPE, language);
		}

		onVerifyCode(verificationCode) {
			if (!verificationCode) {
				return false;
			}

            this.setState({errorCode: null}, () => {
                Flux.Guest.verifyPincall(this.state.verificationId, VERIFICATION_TYPE, verificationCode);
            });
		}

		render() {
			return (<T {...this.state} {...this.props}
			           onSendVerificationCode={this.onSendVerificationCode}
			           onVerifyCode={this.onVerifyCode}
			           isErrorUnique={this.state.errorCode === ERROR_UNIQUE}
			           isErrorTimeout={this.state.errorCode === ERROR_TIMEOUT}
			           isErrorPinWrong={this.state.errorCode === ERROR_PIN_WRONG}
			/>);
		}

	}

	withPincall.propTypes = {
		onFailed:   PropTypes.func,
		onVerified: PropTypes.func,
	};

	return withPincall;
}

export default withPincall;
