import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import PreviewPictures                              from "./Base/Preview/PreviewPictures";
import {TileDisplayConfigType}                      from "../Grid2";
import VideoTileFooter, {VideoTileFooterConfigType} from "./VideoTileFooter";
import HoverContainer                               from "./Base/HoverContainer";
import VideoTileBadges, {VideoTileBadgesConfigType} from "./VideoTileBadges";
import ReloadHelper                                 from "../../../utils/ReloadHelper";
import VideoTileRibbon, {VideoTileRibbonConfigType} from "./VideoTileRibbon";
import Flux                                         from '../../../flux/Flux';

const VideoTile = props => {
	let data          = props.data;
	if(props.data.video !== undefined){
		data          = props.data.video;
	}
	const displayConfig = props.displayConfig;
	const config        = props.config;
	const linkUrl = data.linkVX;
	const onClick = e => ReloadHelper.reload(linkUrl, e);
	const onClickCallback = (e) => {
		e.preventDefault();
		data.callback(data);
	};

	return (
		<a className={'grid-2__tile__type-video h-cursor-pointer'} href={linkUrl} onClick={data.callback ? onClickCallback : onClick}>
			<HoverContainer>
				<PreviewPictures staticItem={data.preview}
				                 items={data.previewThumbnails}
				                 displayConfig={displayConfig}
								 lazyImageLoading={props.lazyImageLoading}
				/>
				<VideoTileBadges data={data} config={config.badges} displayConfig={displayConfig} index={config.index}/>
				<VideoTileRibbon config={config.ribbon} />
			</HoverContainer>
			<VideoTileFooter data={data} displayConfig={displayConfig} config={config.footer} headlineType={props.headlineType}/>
		</a>
	);
};

VideoTile.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL Video type
	config:        PropTypes.shape({
		badges: VideoTileBadgesConfigType,
		footer: VideoTileFooterConfigType,
		ribbon: VideoTileRibbonConfigType,
		index:  PropTypes.number,
	}).isRequired,
	displayConfig:    TileDisplayConfigType.isRequired,
	lazyImageLoading: PropTypes.bool,
	headlineType:      PropTypes.oneOf(Object.values(Flux.Constants.Headlines)),
};

VideoTile.defaultProps = {
	config: {
		badges: VideoTileBadges.defaultProps.config,
		footer: {
			show: {
				actorName: true,
				likes:     false,
				likeBtn:   false,
				buyBtn:    false,
				footer:    true,
				isVip30:   false,
			},
		},
	},
	lazyImageLoading: false,
	headlineType:     Flux.Constants.Headlines.h4,
};

export default VideoTile;
