/* global VXConfig */
import React, {useRef}          from "react";
import PropTypes                from 'prop-types';
import Flux                     from "../../flux/Flux";
import {
    addPreventScrolling, getCurrencySymbol,
    removePreventScrolling
} from '../../utils/CommonUtils';
import Translations             from '../../utils/Translations';
import ReloadHelper             from "../../utils/ReloadHelper";
import UrlBuilder               from "../../utils/UrlBuilder";
import { VXPay }                from "../../utils/VXPay";

function t(key, ...args) {
    return Translations.get('seven-days-bonus.' + key, ...args);
}

function ModalBoxSevenDaysBonus({onClose}) {
	addPreventScrolling();

	const beforeClose = () => {
		removePreventScrolling();
        onClose();
	};

    const rewards    = VXConfig.loginBonus.rewards;
    const smallTiles = [rewards[1], rewards[2], rewards[3], rewards[4], rewards[5], rewards[6]];
    const bigTile    = rewards[7];

    const Header = () => {
        return <div className="seven-days-bonus__header">
        <div className="seven-days-bonus__header-title">
            {t('headline')}
            <span>{t('subline')}</span>
        </div>
        <i className="icon -icon-close-full seven-days-bonus__close" onClick={beforeClose}></i>
    </div>;
    };

    const Footer = () => {
        const isFirstDay = VXConfig.loginBonus.loginDayToday === 1;
        return <div className={"seven-days-bonus__footer" + (isFirstDay ? ' seven-days-bonus__footer--left' : '')}>
                {   isFirstDay ?
                    t('first-page-info') :
                    <span onClick={onClickFooter}>{t('footer')} <i className="icon -icon-single-arrow-right-line"></i></span>
                }
            </div>;
    };

    const getIcon = (type) => {
        switch(type) {
            case Flux.Constants.RewardTypes.FREE_MESSAGES:
                return '-icon-chat-flat';
            case Flux.Constants.RewardTypes.MESSENGER_GIFT:
                return '';
            case Flux.Constants.RewardTypes.VIDEO:
                return '-icon-video-clip';
            case Flux.Constants.RewardTypes.VIP_ABO:
                return '-icon-person-vip';
            case Flux.Constants.RewardTypes.BONUSCODE.toLowerCase():
                return '-icon-euro-full';
            case Flux.Constants.RewardTypes.FREE_PREVIEW:
                return '-icon-eyes-full';
            default:
        }
    };

    const getGiftCodeText = (tile) => {
        switch (tile.codeType) {
            case 'ontop_flat':
                return tile.value + getCurrencySymbol();
            case 'ontop_percent':
                return tile.value + '%';
        }
    };

    const getAmount = (tile) => {
        switch(tile.type) {
            case Flux.Constants.RewardTypes.FREE_PREVIEW:
            case Flux.Constants.RewardTypes.FREE_MESSAGES:
                return tile.value + ' ';
            case Flux.Constants.RewardTypes.MESSENGER_GIFT:
                return tile.value.length > 1 ? tile.value.length + ' ' : '' ;
            case Flux.Constants.RewardTypes.VIP_ABO:
                return tile.value + " " + Translations.get('common.' + (tile.value > 1 ? 'days' : 'day')) + ' ';
            default:
                return '';
        }
    };

    const getText = (tile) => {
        switch(tile.type) {
            case Flux.Constants.RewardTypes.FREE_MESSAGES:
                return getAmount(tile) + t('free-messages');
            case Flux.Constants.RewardTypes.MESSENGER_GIFT:
                return getAmount(tile) + t(tile.value.length !== 1 ? 'messenger-gifts' : 'messenger-gift');
            case Flux.Constants.RewardTypes.VIDEO:
                return t('video-gift');
            case Flux.Constants.RewardTypes.VIP_ABO:
                return getAmount(tile) + t('vip');
            case Flux.Constants.RewardTypes.BONUSCODE.toLowerCase():
                return getGiftCodeText(tile) + ' ' + t('ontop-gift');
            case Flux.Constants.RewardTypes.FREE_PREVIEW:
                return getAmount(tile) + t('free-preview');
            default:
        }
    };

    const onClickTile = (tile) => {
        beforeClose();
        switch(tile.type) {
            case Flux.Constants.RewardTypes.FREE_MESSAGES:
            case Flux.Constants.RewardTypes.MESSENGER_GIFT:
                ReloadHelper.reload(UrlBuilder.buildMessengerRoute());
                break;
            case Flux.Constants.RewardTypes.VIDEO:
                ReloadHelper.reload(UrlBuilder.buildMyVisitXGiftedVideosRoute());
                break;
            case Flux.Constants.RewardTypes.VIP_ABO:
                ReloadHelper.reload(UrlBuilder.buildVIP30Url());
                break;
            case Flux.Constants.RewardTypes.BONUSCODE.toLowerCase():
                VXPay.openPaytour({option: tile.code});
                break;
            default:
        }
    };

    const onClickFooter = () => {
        beforeClose();
        ReloadHelper.reload(UrlBuilder.buildMyVisitXBenefitsRoute());
    };

    const isActive = (index) => {
        return (index + 1) === VXConfig.loginBonus.loginDayToday;
    };

    const getIconWrapper = (tile, index) => {
        return  <>
                <div className={"seven-days-bonus__body-item__icon-wrapper"}>
                    { tile.type === Flux.Constants.RewardTypes.MESSENGER_GIFT  ?
                        <img className="seven-days-bonus__body-item__icon--image" src={tile.imageUrl} />
                    :
                        <i className={"seven-days-bonus__body-item__icon icon " + getIcon(tile.type)} />
                    }
                    <i className="seven-days-bonus__body-item__icon-check icon -icon-success-rounded-full" />
                </div>
                <div className="seven-days-bonus__body-item__text">{ getText(tile)}</div>
        </>;
    };

    const SmallTiles = () => {
        return <div className="seven-days-bonus__body-item__wrapper seven-days-bonus__body-item__wrapper--multiple">
                    { smallTiles.map((tile, index) => (
                            <div className={"seven-days-bonus__body-item" + (isActive(index) ? " seven-days-bonus__body-item--active" : "") + (tile.claimed ? ' seven-days-bonus__body-item--claimed' : '')} onClick={() =>  isActive(index)  ? onClickTile(tile) : null} key={index}>
                    {(tile.claimed || isActive(index)) && <div className={"seven-days-bonus__body-item__background"}>
                        <div className={"seven-days-bonus__body-item__background-effect " + (isActive(index) ? 'seven-days-bonus__body-item__background-effect--small-forward' : 'seven-days-bonus__body-item__background-effect--small')}></div>
                        <div className={"seven-days-bonus__body-item__background-effect " + (isActive(index) ? 'seven-days-bonus__body-item__background-effect--small-forward-2' : 'seven-days-bonus__body-item__background-effect--small-2')}></div>
                    </div>}
                    <div className="seven-days-bonus__body-item__badge">{Translations.get('common.day')} {index + 1}</div>
                                {getIconWrapper(tile, index)}
                            </div>
                        ))}
                </div>;
    };

    const BigTile = () => {
        const bigTileIndex = 6;
        return <div className="seven-days-bonus__body-item__wrapper">
            <div className={"seven-days-bonus__body-item seven-days-bonus__body-item--big" + (isActive(bigTileIndex) ? " seven-days-bonus__body-item--active" : "")} onClick={() =>  isActive(bigTileIndex)  ? onClickTile(bigTile) : null} >
               { isActive(bigTileIndex) ? <div className={"seven-days-bonus__body-item__background"}>
                    <div className={"seven-days-bonus__body-item__background-effect seven-days-bonus__body-item__background-effect--small-forward"}></div>
                    <div className={"seven-days-bonus__body-item__background-effect seven-days-bonus__body-item__background-effect--small-forward-2"}></div>
                    </div> :
                    <div className={"seven-days-bonus__body-item__background"}>
                        <div className="seven-days-bonus__body-item__background-effect"></div>
                        <div className="seven-days-bonus__body-item__background-effect seven-days-bonus__body-item__background-effect--2"></div>
                    </div>
                }
                <div className="seven-days-bonus__body-item__badge">{Translations.get('common.day')} 7</div>
                { isActive(bigTileIndex) ?
                    getIconWrapper(bigTile, bigTileIndex) :
                    <img className="seven-days-bonus__body-item__image" src={"/assets/img/sevenDaysBonus/" + (Flux.Browser.isSpanishPfm() ? "special_gift_es.svg" : "special_gift.svg")} />
                }
            </div>
        </div>;
    };

	return <div className={"seven-days-bonus__wrapper"}>
		<div className={"seven-days-bonus"}>
            <Header />
            <div className="seven-days-bonus__body">
                <SmallTiles />
                <BigTile />
            </div>
            <Footer />
		</div>
	</div>;
}

ModalBoxSevenDaysBonus.propTypes = {
	onClose: PropTypes.func,
};

ModalBoxSevenDaysBonus.defaultProps = {
	onClose: () => {},
};

export default React.memo(ModalBoxSevenDaysBonus, true);
