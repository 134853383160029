import React, {useState, useRef,
        useEffect}                 from "react";
import PropTypes                   from 'prop-types';

import Flux                        from "../../flux/Flux";
import {addPreventScrolling,
        removePreventScrolling}    from '../../utils/CommonUtils';
import style                       from './ModalBoxFifteenMinutesPreview.module.less';
import Translations                from "../../utils/Translations";
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import STYLES                      from "../SimpleElements/VXButton/EnumVXButton";
import { getCountdownTillDate }    from "../Helper/CountdownHelper";
import UrlBuilder                  from "../../utils/UrlBuilder";
import withPincall                 from "../HigherOrderComponents/Utility/withPincall";

const STEPS = {
    INTRO:        1,
    PHONE:        2,
    CODE:         3,
    RETRY_ERROR:  4,
    ALREADY_USED: 5,
    SUCCESS:      6
};

function t(value, ...args) {
    return Translations.get('fifteen-minutes-preview.' + value, args);
}

const imageUrl  = '/assets/img/modal/fifteen-minutes/';
const _list     = [false, false, true, false];
const COUNTRIES = [
    {
        value: '+34',
        option: <><span className={style.countryFlag + " "  + style.countryFlagEs} /> +34</>
    },
    {
        value: '+49',
        option: <><span className={style.countryFlag + " "  + style.countryFlagDe} /> +49</>
    },
    {
        value: '+31',
        option: <><span className={style.countryFlag + " "  + style.countryFlagNl} /> +31</>
    },
];

let timer = null;

function ModalboxFifteenMinutesPreview({onClose, onSendVerificationCode, onVerifyCode, errorCode, isVerified, verificationId, pinLength = Flux.Constants.Pincall.PinCallLength.MOBILE}) {
	addPreventScrolling();

    //TODO remove -->
        let initialStep = STEPS.INTRO;
        const queryString = window.location.search;
        const urlParams   = new URLSearchParams(queryString);
        if (urlParams.get('step')) {
            initialStep = parseInt(urlParams.get('step'));
        }
    //<---

    const [step, setStep]                       = useState(initialStep);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [showPhoneList, setShowPhoneList]     = useState(false);
    const [showCodeError, setShowCodeError]     = useState(false);
    const [showPhoneError, setShowPhoneError]   = useState(false);
    const [showCodeSuccess, setShowCodeSuccess] = useState(false);
    const [countdown, setCountdown]             = useState({hours: "00", mins: "15", sec: "00"});

    const codeInput1Ref = useRef(null);
    const codeInput2Ref = useRef(null);
    const codeInput3Ref = useRef(null);
    const codeInput4Ref = useRef(null);
    const codeInput5Ref = useRef(null);
    const codeInput6Ref = useRef(null);
    const codeInputRefs = [codeInput1Ref, codeInput2Ref, codeInput3Ref, codeInput4Ref, codeInput5Ref];
    pinLength === Flux.Constants.Pincall.PinCallLength.MOBILE && codeInputRefs.push(codeInput6Ref);
    const phoneRef      = useRef(null);

    useEffect(() => {
        switch (errorCode) {
            case Flux.Constants.Pincall.ErrorCodes.PHONE_NUMBER_NOT_UNIQUE:
                setStep(STEPS.ALREADY_USED);
                break;
            case Flux.Constants.Pincall.ErrorCodes.ATTEMPT_LIMIT_REACHED:
                setStep(STEPS.RETRY_ERROR);
                break;
            case Flux.Constants.Pincall.ErrorCodes.INVALID_CODE:
                setShowCodeError(true);
                setStep(STEPS.CODE);
                break;
            case Flux.Constants.Pincall.ErrorCodes.VERIFICATION_TIME_OUT:
                setStep(STEPS.CODE);
                break;
            case Flux.Constants.Pincall.ErrorCodes.INVALID_PHONE_NUMBER:
                phoneRef.current.classList.add(style.contentPhoneInputError);
                setShowPhoneError(true);
                break;
        }
    }, [errorCode]);

    useEffect(() => {
        if (isVerified) {
            setShowCodeSuccess(true);
        }
    }, [isVerified]);

    useEffect(() => {
        if (verificationId) {
            setStep(STEPS.CODE);
        }
    }, [verificationId]);

    useEffect(() => {
        if (showCodeSuccess) {
            Flux.Guest.initVxEsFreePreview();
            const now = new Date();
            const fifteenMinutes =  now.setTime((now.getTime() + 15 * 60000) + 1000);
            setTimeout(() => {
                setStep(STEPS.SUCCESS);
                setShowCodeSuccess(false);

                timer = window.setInterval(() => {
                    const {hours, mins, sec} = getCountdownTillDate(fifteenMinutes);
				    setCountdown({hours, mins, sec});
                }, 1000);
            }, 1500);
        }
    }, [showCodeSuccess]);

    useEffect(() => {
        return () => {
            timer && window.clearInterval(timer);
        };
    }, []);

    const decline = () => {
        Flux.Guest.initVxEsFreePreview(step !== STEPS.SUCCESS);
        if (step === STEPS.SUCCESS) {
            window.location.href = UrlBuilder.buildCamsRoute();
        }
        beforeClose();
    };

    const beforeClose = () => {
        removePreventScrolling();
        onClose();
    };

    const getIntroHeadline = (isBig = false) => {
        return <div className={style.introHeadline + " " + (isBig && style.introHeadlineBig)}>{t('intro-headline')}</div>;
    };

    const getIntroList = (isBig = false) => {
        const icon = <i className={"icon -icon-round-success-full " + style.introListIcon} />;
        return <ul className={style.introList + " " + (isBig && style.introListBig)}>
            {_list.map((isHD, index) => <li className={style.introListItem} key={index}>{icon} {t('intro-list--' + (index + 1))} {isHD && <img src={imageUrl + "hd_logo.svg"} />}</li>)}
        </ul>;
    };

    const getFooter = () => {
        if (step === STEPS.SUCCESS) {
            return <div className={style.footer + " " + style.footerLast}>
                <div className={style.footerText}>{t('success-footer')}</div>
            </div>;
        }

        return <div className={style.footer}>
            <img  className={style.footerImage} src={imageUrl + 'badges.png'} />
            <div className={style.footerText}>{t('footer-text--1')}</div>
            <div className={style.footerText}>{t('footer-text--2')}</div>
        </div>;
    };

    const stepIntro = () => {
        return <div className={style.grid}>
            <div className={style.gridItemLeft}>
            <div className={style.gridItemTop}></div>
            <div className={style.gridItemBottom}>
                <img className={style.logo} src={imageUrl + "logo2.svg"} />
            </div>
            </div>
            <div className={style.gridItemRight}>
                <div className={style.gridItemTop + " " + style.gridItemTop1}>
                    <img src="/assets/img/logo_shadev3Line_black.svg" className={style.vxlogo} />
                </div>
                <div className={style.gridItemBottom}>
                    {getIntroHeadline(true)}
                    {getIntroList(true)}
                    <div className={style.introButton}>
                        <VXButton line1={[new VXButtonLineConfig(t('intro-button'))]}
                            color={COLOR_TYPE_CALL_TO_ACTION}
                            onClick={() => setStep(STEPS.PHONE)}
                            staticMode={true}
                        />
                    </div>
                    <div className={style.introSubline}>{t('intro-subline')}</div>
                </div>
            </div>
        </div>;
    };

    const contentWrapper = (content) => {
        return <div className={style.grid + " " + style.gridStep2}>
            <div className={style.gridItemLeft}>
                <div className={style.gridItemTop}>
                    <img className={style.logo} src={imageUrl + "logo2.svg"} />
                </div>
                <div className={style.gridItemBottom}>
                    {getIntroHeadline()}
                    {getIntroList()}
                </div>
            </div>
            <div className={style.gridItemRight + " " + style.gridItemRightStep2}>
                <div className={style.gridItemTop}>
                    <img src="/assets/img/logo_shadev3Line_black.svg" className={style.vxlogo} />
                    {content}
                </div>
                {getFooter()}
            </div>
        </div>;
    };

    const onClickCountry = (index) => {
        setSelectedCountry(index);
        phoneRef.current.value = null;
        phoneRef.current.focus();
    };

    const onPhoneChange = (e) => {
        phoneRef.current.classList.remove(style.contentPhoneInputError);
        if ([69, 110, 109, 107].includes(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (e.keyCode === 13) {
            validatePhone();
        }
    };

    const validatePhone = () => {
        const phone = phoneRef.current.value;
        if (!phone || showPhoneError) {
            phoneRef.current.classList.add(style.contentPhoneInputError);
            return;
        }

        const phoneNumber = COUNTRIES[selectedCountry].value + phone;
        onSendVerificationCode(phoneNumber);
    };

    const getPhoneSelector = () => {
        return <div className={style.contentPhoneSelectorWrapper}>
            <div className={style.selector  + " " + (showPhoneList && style.selectorShow)} onClick={() => setShowPhoneList(!showPhoneList)}>
                {COUNTRIES[selectedCountry].option}
                <i className={style.selectorIcon + " icon -icon-single-arrow-down-line"} />
                <div className={style.selectorList}>
                    {COUNTRIES.map((country, index) => <div className={style.selectorListItem} onClick={() => onClickCountry(index)} key={index}>{country.option}</div>)}
                </div>
            </div>
            <input autoFocus className={style.contentPhoneInput} type="number" onKeyDown={onPhoneChange} placeholder={t('phone-placeholder')} ref={phoneRef} />
        </div>;
    };

    const stepPhone = () => {
        return contentWrapper(<div className={style.content}>
            <div className={style.contentHeadline}>{t('headline-confirm')}</div>
            {showPhoneError && <div className={style.contentPhoneError}>{t('phone-error')}</div>}
            {getPhoneSelector()}
            <div className={style.phoneInfo}>{t('phone-info')}</div>
            <div className={style.phoneCodeSend}>{t('phone-code-send')}</div>
            <div className={style.phoneButton}>
                <VXButton line1={[new VXButtonLineConfig(t('phone-request-code'))]}
                    color={COLOR_TYPE_CALL_TO_ACTION}
                    onClick={validatePhone}
                    staticMode={true}
                />
            {/*<div className={style.phoneAlreadyCode} onClick={() => setStep(STEPS.CODE)}>{t('phone-already-code')}</div>*/}
            </div>
        </div>);
    };

    const validateCode = () => {
        let isEmpty = false;

        codeInputRefs.map(ref => {
            ref.current.blur();
            if (!ref.current.value) {
                ref.current.classList.add(style.codeInputError);
                isEmpty = true;
            }
        });

        if (isEmpty) {
            return;
        }
        const code = codeInputRefs.map(ref => ref.current.value).join('');
        onVerifyCode(code);
    };

    const checkCodeKey = (e) => {
        if ([69, 110, 109, 107].includes(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (e.keyCode === 13) {
            if (e.target.value.length > 0) {
                if (e.target.nextElementSibling) {
                    e.target.nextElementSibling.focus();
                }  else {
                    validateCode();
                }
            }
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (e.target.previousElementSibling && e.target.value.length === 0 && e.keyCode === 8) {
            e.preventDefault();
            e.stopPropagation();
            e.target.previousElementSibling && e.target.previousElementSibling.focus();
            e.target.previousElementSibling.value = '';
        } else if (e.target.previousElementSibling && e.keyCode === 37 || e.keyCode === 40 ) {
            e.preventDefault();
            e.stopPropagation();
            e.target.previousElementSibling && e.target.previousElementSibling.focus();
        } else if (e.target.nextElementSibling && e.keyCode === 39 || e.keyCode === 38) {
            e.preventDefault();
            e.stopPropagation();
            e.target.nextElementSibling && e.target.nextElementSibling.focus();
        } else {
            e.target.value = String.fromCharCode(e.key);
        }
    };

    const checkCodeLength = (e) => {
       if (e.target.value.length === 1 && e.target.nextElementSibling) {
            e.target.nextElementSibling.focus();
        }
    };

    const onInputFocus = (e) => {
        e.target.classList.remove(style.codeInputError);
        if (showCodeError) {
            setShowCodeError(false);
            codeInputRefs.map(ref => ref.current.value = null);
        }
    };

    const stepCode = () => {
        return contentWrapper(<div className={`${style.content} ${style.contentSpaceSm} ${style.contentSpaceLeft}`}>
            <div className={style.codeSuccess}>
                <i className={style.codeSuccessIcon + ' icon -icon-success-rounded-full'} />
                <span>{t('code-send')}</span>
            </div>
            <div className={style.codeInfo}>{t('code-info')}</div>

            <div className={`${style.codeWrapper} ${showCodeError && style.codeWrapperError} ${showCodeSuccess && style.codeWrapperSuccess}`}>
                <form className={style.codeInputWrapper}>
                    <input onFocus={onInputFocus} autoFocus onInput={checkCodeLength} onKeyDown={checkCodeKey} type="number" maxLength="1" className={style.codeInput} ref={codeInput1Ref}></input>
                    <input onFocus={onInputFocus} onInput={checkCodeLength} onKeyDown={checkCodeKey} type="number" maxLength="1" className={style.codeInput} ref={codeInput2Ref}></input>
                    <input onFocus={onInputFocus} onInput={checkCodeLength} onKeyDown={checkCodeKey} type="number" maxLength="1" className={style.codeInput} ref={codeInput3Ref}></input>
                    <input onFocus={onInputFocus} onInput={checkCodeLength} onKeyDown={checkCodeKey} type="number" maxLength="1" className={style.codeInput} ref={codeInput4Ref}></input>
                    <input onFocus={onInputFocus} onInput={checkCodeLength} onKeyDown={checkCodeKey} type="number" maxLength="1" className={style.codeInput} ref={codeInput5Ref}></input>
                    {pinLength === Flux.Constants.Pincall.PinCallLength.MOBILE && <input onFocus={onInputFocus} onInput={checkCodeLength} onKeyDown={checkCodeKey} type="number" maxLength="1" className={style.codeInput} ref={codeInput6Ref}></input>}
                </form>
                <div className={style.codeButton}>
                    <VXButton line1={[new VXButtonLineConfig(t('code-button'))]}
                        color={COLOR_TYPE_CALL_TO_ACTION}
                        onClick={validateCode}
                        icon="-icon-single-arrow-right-line"
                        iconPosition={STYLES.ICON_POSITION.RIGHT}
                        staticMode={true}
                    />
                </div>
                <div className={style.codeNoSms} onClick={() => setStep(STEPS.PHONE)}>{t('code-no-sms')}</div>
                <div className={style.codeWrong}>{t('code-wrong')}</div>
                <div className={style.codeChangeNumber} onClick={() => setStep(STEPS.PHONE)}>{t('code-change-number')}</div>
            </div>
        </div>);
    };

    const stepRetryError = () => {
        return contentWrapper(<div className={`${style.content} ${style.contentSpace} ${style.contentPadding}`}>
            <div>
                <div className={style.contentHeadline}>{t('retry-error-headline')}</div>
                <div className={style.codeInfo + " " + style.codeInfoCenter}>{t('retry-error-info')}</div>
            </div>

            <VXButton line1={[new VXButtonLineConfig(t('to-startpage'))]}
                color={COLOR_TYPE_CALL_TO_ACTION}
                onClick={decline}
                staticMode={true}
            />
        </div>);
    };

    const stepAlreadyUsed = () => {
        return contentWrapper(<div className={`${style.content} ${style.contentSpace} ${style.contentPadding}`}>
            <div className={style.contentHeadline}>{t('already-used-headline')}</div>
            <VXButton line1={[new VXButtonLineConfig(t('to-startpage'))]}
                color={COLOR_TYPE_CALL_TO_ACTION}
                onClick={decline}
                staticMode={true}
            />
        </div>);
    };

    const onClickAmateur = () => {
        beforeClose();
        const params = new URLSearchParams();
        params.set("filter", JSON.stringify({livePreview: "LIVE"}));
		const urlString = UrlBuilder.buildCamsRoute() + '?' + params.toString();
        window.location.href = urlString;
    };

    const stepSuccess = () => {
        return contentWrapper(<div className={`${style.content} ${style.contentSpaceSm}  ${style.contentPadding}`}>
                <div className={style.codeSuccess + " " + style.codeSuccessBig}>
                    <i className={style.displaySm + " " + style.codeSuccessIcon + ' icon -icon-success-rounded-full'} />
                    <span>{t('success-headline')}</span>
                </div>
                <div className={style.successInfoWrapper}>
                    <div className={style.successInfo}>{t('success-info')}</div>
                    <div className={style.successCountdown}>{`${countdown.hours}:${countdown.mins}:${countdown.sec}`}</div>
                </div>
                <VXButton line1={[new VXButtonLineConfig(t('success-button'))]}
                    color={COLOR_TYPE_CALL_TO_ACTION}
                    onClick={onClickAmateur}
                    icon="-icon-single-arrow-right-line"
                    iconPosition={STYLES.ICON_POSITION.RIGHT}
                    staticMode={true}
                />
        </div>);
    };

    const getStep = () => {
        switch (step) {
            case STEPS.INTRO:
                return stepIntro();
            case STEPS.PHONE:
                return stepPhone();
            case STEPS.CODE:
                return stepCode();
            case STEPS.RETRY_ERROR:
                return stepRetryError();
            case STEPS.ALREADY_USED:
                return stepAlreadyUsed();
            case STEPS.SUCCESS:
                return stepSuccess();
        }
    };

	return <div className={style.wrapper}>
		<div className={style.modal}>
            <div className={`icon -icon-close-line ${style.closeButton} ${step === 1 && style.closeButtonWhite}`} onClick={decline}></div>
			{getStep()}
		</div>
	</div>;
}

ModalboxFifteenMinutesPreview.propTypes = {
    onClose:                PropTypes.func,
    onSendVerificationCode: PropTypes.func,
    onVerifyCode:           PropTypes.func,
    errorCode:              PropTypes.number,
    isVerified:             PropTypes.bool,
    verificationId:         PropTypes.string,
    pinLength:              PropTypes.number,
};

export default withPincall(ModalboxFifteenMinutesPreview);
