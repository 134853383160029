/* global VXConfig */
import React, {useState}                                from "react";
import PropTypes                                        from 'prop-types';
import VXButton                                         from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_DISABLED} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}                             from "../SimpleElements/VXButton/VXButtonConfig";
import {
	addPreventScrolling,
	removePreventScrolling
}                                                       from '../../utils/CommonUtils';
import ReloadHelper                                     from "../../utils/ReloadHelper";
import LogoLoader                                       from "../Loader/LogoLoader";
import Flux                                             from "../../flux/Flux";
import { VXPay }                                        from "../../utils/VXPay.js";
import {getPreFilteredPreferences, setGridRef, t}                      from "./ModalBoxOnboarding.js";

export default function ModalBoxOnboardingChoose({onClose}) {
	addPreventScrolling();

	const [tags, setTags]     = useState([...getPreFilteredPreferences(VXConfig.allGuestPreferences)]);
	const [isSelected, setIsSelected] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const onClickNext = () => {
			setShowLoader(true);
			const preferences   = {};
			tags.map(group => {
				group.tags.map(tag => {
					if(tag.isSelected) {
						if(!preferences[group.id]) {
							preferences[group.id] = [];
						}
						preferences[group.id].push(tag.id);
					}
				});
			});

			Flux.Guest.addGuestPreferences(preferences, (res) => {
				if (res.success) {
                    onClickClose();
				}
			});
	};

	const onClickClose = (e) => {
		beforeClose();
	};

    const beforeClose = () => {
		removePreventScrolling();
		onClose();
	};

	const selectedTag = (categorie, tagId) => {
		let selectedCount = 0;
		const groups = [...tags];
		groups.map(group => {
			group.tags.map(tag => {
				if(tag.id === tagId && group.id === categorie) {
					tag.isSelected = !tag.isSelected;
				}
				if (tag.isSelected) {
					selectedCount++;
				}
			});
		});
		if (selectedCount > 0) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
		setTags(getPreFilteredPreferences(groups));
	};


	return <div className="onboard-modal__wrapper">
		<div className={"onboard-modal onboard-modal--2"}>
			{showLoader && <LogoLoader pulse={true} overlayClassName="-absolute" showOverlay />}
			<div className="onboard-modal__header">
                    <span className="onboard-modal__header-title onboard-modal__header-title--subline">{t('headline-2')} <span>{t('headline-2-subline')}</span></span>
                    <i className="icon -icon-close-full onboard-modal__close" onClick={onClickClose}></i>
            </div>
			<div className="onboard-modal__content">
				<div className={"onboard-modal__body" + (tags.length > 0 ? ' onboard-modal__body--grid' : ' onboard-modal__body--full')} ref={ref => setGridRef(ref)}>
					{tags.map((group, index) =>
						!group.isHidden && (<div className="onboard-modal__body-group" key={index}>
							<div className="onboard-modal__body-group__title">{group.category}</div>
							<div className="onboard-modal__body-group__tags">
								{group.tags.map((tag, idx) => <div onClick={() => selectedTag(group.id, tag.id)} className={"onboard-modal__body-group__tag" + (tag.isSelected ? ' onboard-modal__body-group__tag--selected' : '')} key={idx}>{tag.value}</div>)}
							</div>
						</div>)
					)}
                    {tags.length === 0 && !Flux.Guest.isLoggedIn() && <VXButton
						color={COLOR_TYPE_CALL_TO_ACTION}
						line1={[new VXButtonLineConfig(t('login'))]}
						onClick={VXPay.openSignupOrLogin}
						staticMode={true}
					/>}
				</div>
				<div className="onboard-modal__footer">
                    <div className="onboard-modal__footer-button">
                        <VXButton
                            color={!isSelected ? COLOR_TYPE_DISABLED : COLOR_TYPE_CALL_TO_ACTION}
                            line1={[new VXButtonLineConfig(t('button-next'))]}
                            onClick={onClickNext}
                            staticMode={true}
                            disabled={!isSelected}
                        />
                    </div>
                </div>
			</div>
		</div>
	</div>;
}

ModalBoxOnboardingChoose.propTypes = {
	onClose: PropTypes.func,
};

ModalBoxOnboardingChoose.defaultProps = {
	onClose: () => {},
};
